import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {CustomerMiscService} from '@customer/customer-misc.service';
import {PaymentConditionModel} from '@customer/models';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'omt-payment-condition-dialog',
    templateUrl: './payment-condition-dialog.component.html',
    styleUrls: ['./payment-condition-dialog.component.scss']
})
export class PaymentConditionDialogComponent implements OnInit {
    saveEmitter = new EventEmitter<PaymentConditionModel>();

    constructor(
        public dialogRef: MatDialogRef<PaymentConditionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public paymentConditionModel: PaymentConditionModel,
        private readonly customerMiscService: CustomerMiscService,
        private readonly translate: TranslateService
    ) {
    }

    title: string;

    ngOnInit(): void {
        if (this.paymentConditionModel.id) {
            this.title = this.translate.instant('MANAGEMENT.EDIT_TERM_OF_PAYMENT');
        } else {
            this.title = this.translate.instant('MANAGEMENT.CREATE_TERM_OF_PAYMENT');
        }

    }

    closeModal(): void {
        this.dialogRef.close();
    }

    save(): void {
        if (this.paymentConditionModel.id) {
            this.customerMiscService.updatePaymentCondition(this.paymentConditionModel).subscribe(() => {
                this.saveEmitter.emit(this.paymentConditionModel);
                this.closeModal();
            });
        } else {
            this.customerMiscService.createPaymentCondition(this.paymentConditionModel).subscribe(() => {
                this.saveEmitter.emit(this.paymentConditionModel);
                this.closeModal();
            });
        }
    }
}
